.home {
    overflow: hidden;

    &__hero {
        height: 120vh;
        width: 100%;

        @include mq(sm) {
            height: 33.34vw;
        }

        &__slide {
            height: 120vh;

            @include mq(sm) {
                display: inline-flex !important;
                height: 33.34vw;
            }
        }

        &__col {
            height: 100%;

            &--image {
                position: relative;
                height: 100vh;

                @include mq(sm) {
                    width: 66.66%;
                    height: auto;
                }

                img {
                    position: absolute;
                    height: 100%;
                    width: auto;
                    transform: translateX(-50%);

                    @include mq(sm) {
                        width: 100%;
                        height: auto;
                        transform: translateX(0);
                    }
                }
            }

            &--pattern {
                background: none;
                position: relative;

                @include mq(sm) {
                    height: 33.34vw;
                    width: 33.33%;
                    background: url('../images/home/pattern1.gif');
                }
            }
        }

        &__data {
            position: absolute;
            left: 50%;
            top: 20%;
            transform: translate3d(-50%, -50%, 0);

            @include mq(sm) {
                top: 50%;
            }
        }

        &__title {
            text-align: center;
            color: $white;
            font-size: 45px;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1;
            margin: 0;

            @include mq(sm) {
                font-size: 7vw;
            }
        }

        &__button {
            position: absolute;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            color: $white;
            background: $lila;
            text-transform: uppercase;
            border-radius: 50px;
            font-size: 16px;
            text-align: center;
            width: 80%;
            padding: 10px 20px;
            top: 102px;

            @include mq(sm) {
                width: auto;
                top: 50%;
                font-size: 1.3vw;
            }
        }

        &__bottles {
            position: absolute;
            right: -17%;
            bottom: 0;

            @include mq(sm) {
                left: 0;
                right: auto;
                transform: translateX(-35%);
            }
        }
    }

    &__malbec,
    &__blanc,
    &__malbec-reserva,
    &__pasita {

        @include mq(sm) {
            height: 33.34vw;
        }

        &__image {
            &--left {
                position: relative;
                overflow: hidden;
                height: 33.34vh;

                @include mq(sm) {
                    height: 33.34vw;
                }

                img {
                    height: auto;
                    width: 100%;
                    transform: translateY(-30%);

                    @include mq(sm) {
                        height: 100%;
                        width: auto;
                        transform: translateY(0);
                    }
                }
            }

            &--bottle {
                width: 30%;
                height: 100%;

                @include mq(sm) {
                    width: 50%;
                    top: 15%;
                    left: 40%;
                }

                img {
                    width: auto;
                    height: 100%;
                    transform: rotate(15deg) scale3d(1.3,1.3,1.3) translateX(-25%) translateY(20%);

                    @include mq(sm) {
                        transform: rotate(15deg) scale3d(1.3,1.3,1.3) translateY(5%);
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &--right {
                overflow: hidden;
                height: 33.34vh;

                @include mq(sm) {
                    height: 33.34vw;
                }

                img {
                    width: 100%;
                    height: auto;

                    @include mq(sm) {
                        transform: translateX(-35%);
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }

        &__wrapper {
            display: flex;
            background: url('../images/home/pattern2.gif');
            overflow: hidden;
            position: relative;
            height: 75vh;
            width: 100%;

            @include mq(sm) {
                height: 33.34vw;
            }
        }

        &__data {
            padding: 30px 0 0 20px;
            width: 75%;

            @include mq(sm) {
                width: 50%;
                padding: 10% 0 10% 10%;
            }
        }

        &__title {
            margin: 0;
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 40px;
            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 10%;
                font-size: 40px;
            }
        }

        &__text {
            color: $white;
            margin-bottom: 30px;
            font-size: 18px;

            @include mq(sm) {
                margin-bottom: 10%;
            }
        }

        &__price {
            color: $white;
            margin-bottom: 30px;

            @include mq(sm) {
                margin-bottom: 10%;
            }
        }

        &__button {
            background: $white;
            padding: 10px 20px;
            border-radius: 50px;
            font-weight: 700;
            color: $black;
            font-size: 16px;
            display: inline-block;

            @include mq(sm) {
                font-size: 14px;

            }
        }
    }

    &__blanc {
        &__wrapper {
            background: url('../images/home/pattern3.gif');
        }

        &__image {
            &--bottle  {
                @include mq(sm) {
                    height: 100%;
                }

                img {
                    transform: rotate(0) scale3d(1.3,1.3,1.3) translateX(-25%) translateY(20%);

                    @include mq(sm) {
                        transform: rotate(0) scale3d(1.5,1.5,1.5) translateY(-15%);
                    }
                }
            }

            &--right {
                overflow: hidden;
                height: 33.34vh;

                @include mq(sm) {
                    height: 33.34vw;
                }

                img {
                    width: auto;
                    height: 180%;
                    transform: translateX(0) translateY(-20%);

                    @include mq(sm) {
                        width: auto;
                        height: 100%;
                        transform: translateX(0) translateY(0);
                    }
                }
            }
        }
    }


    &__malbec-reserva {
        .row {
            display: flex;
            flex-direction: column;

            @include mq(sm) {
                flex-direction: row;
            }
        }

        &__position {
            &:nth-child(1) {
                order: 2;

                @include mq(sm) {
                    order: 1;
                }
            }

            &:nth-child(2) {
                order: 3;

                @include mq(sm) {
                    order: 2;
                }
            }

            &:nth-child(3) {
                order: 1;

                @include mq(sm) {
                    order: 3;
                }
            }
        }

        &__image {
            overflow: hidden;
            height: 33.34vw;

            img {
                width: auto;
                height: 100%;
            }

            &--left {
                height: 33.34vh;

                @include mq(sm) {
                    height: 33.34vw;
                }

                img {
                    height: auto;
                    width: 100%;
                }
            }

            &--right {
                height: 33.34vh;

                @include mq(sm) {
                    height: 33.34vw;
                }

                img {
                    width: 100%;
                    height: auto;

                    @include mq(sm) {
                        transform: translateX(-30%);
                        width: auto;
                        height: 100%;
                    }
                }
            }

            &--bottle {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 40%;

                @include mq(sm) {
                    position: static;
                    width: 50%;
                    height: 100%;
                }

                img {
                    transform: rotate(-15deg);

                    @include mq(sm) {
                        width: auto;
                        height: 100%;
                        transform: rotate(-15deg);
                    }
                }
            }
        }

        &__wrapper {
            background: url('../images/home/pattern4.gif');
            display: flex;
            height: 75vh;

            @include mq(sm) {
                height: 33.34vw;
            }
        }

        &__data {
            padding: 30px 0 0 30px;
            width: 75%;

            @include mq(sm) {
                height: 100%;
                width: 50%;
                padding-right: 10%;
                padding-top: 10%;
            }
        }

        &__title {
            margin: 0;
            color: $white;
            text-transform: uppercase;
            margin-bottom: 10%;
            font-size: 40px;
            font-weight: 700;
        }

        &__text {
            color: $white;

        }

        &__price {
            color: $white;
            margin-bottom: 10%;
        }

        &__button {
            background: $white;
            padding: 10px 20px;
            border-radius: 50px;
            font-weight: 700;
            color: $black;
            font-size: 14px;
        }
    }

    &__pasita {
        &__image {
            &--left {
                display: none;

                @include mq(sm) {
                    display: block;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }

            &--bottle {
                width: 30%;
                position: absolute;

                @include mq(sm) {
                    position: static;
                    width: 50%;
                    height: 100%;
                }

                img {
                    transform: rotate(0deg) translateX(42%);
                    width: auto;
                    height: 100%;

                    @include mq(sm) {
                        width: auto;
                        height: 100%;
                        transform: rotate(0deg) translateX(0);
                    }
                }
            }

            &--right {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                    transform: translateX(0);

                    @include mq(sm) {
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }

        &__data {
            padding: 30px;
            width: 70%;
            position: relative;
            z-index:2;

            @include mq(sm) {
                height: 100%;
                width: 50%;
                padding-right: 5%;
                padding-top: 10%;
            }
        }

        &__wrapper {
            background: url('../images/home/pattern1.gif');
        }
    }
}
