.carro-de-compras {
    &__list {

        &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-weight: 900;
            text-transform: uppercase;
            margin: 0;
            font-size: 24px;
            background: url('../images/home/hero.jpg') no-repeat left center;
            height: 40vh;

            @include mq(sm) {
                height: 20vw;
                font-size: 3vw;
            }
        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 3vw;

        @include mq(sm) {
            margin-bottom: 0;
        }

        &__heading {
            display: none;

            @include mq(sm) {
                display: flex;
                width: 100%;
                border-bottom: 1px solid $lightGray;
                padding: 1vw 0;
            }
        }

        &__cell {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            width: 100%;

            @include mq(sm) {
                flex-direction: row;
            }

            p {
                font-weight: 500;
            }

            &:nth-child(1) {
                width: 100%;
                @include mq(sm) {

                    width: 35%;
                    justify-content: flex-start;
                }

                label {
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 500;
                    border-bottom: 1px solid $lightGray;
                    width: 100%;
                    padding-bottom: 20px;
                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            &:nth-child(2) {
                width: 33.33%;
                text-align: center;

                @include mq(sm) {
                    text-align: none;
                    width: 20%;
                }

                label {
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 500;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            &:nth-child(3) {
                width: 33.33%;

                @include mq(sm) {
                    width: 20%;
                }

                label {
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 500;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            &:nth-child(4) {
                width: 33.33%;
                text-align: center;

                @include mq(sm) {
                    text-align: none;
                    width: 20%;
                }

                label {
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 500;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            &:nth-child(5) {
                width: 5%;
            }

            p {
                font-size: 12px;
                color: $black;
                text-transform: uppercase;
                margin: 0;

                @include mq(sm) {
                    font-size: 1.2vw;
                }
            }
        }
    }

    &__item {
        flex-wrap: wrap;
        padding: 10px 0;
        display: flex;
        width: 100%;
        border-bottom: 1px solid $lightGray;
        position: relative;
        margin-bottom: 20px;

        @include mq(sm) {
            height: 20vw;
            padding: 1vw 0;
            border-top: none;
            margin-bottom: 0;
        }

        &__wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            border-bottom: 1px solid $lightGray;

            @include mq(sm) {
                margin-bottom: 0;
                border-bottom: none;
            }

            h3 {
                font-size: 40px;
                margin: 0;
                line-height: 1.3;
                text-transform: uppercase;
                color: $mainColor;
                font-weight: 900;

                @include mq(sm) {
                    font-size: 3vw;
                }
            }
        }

        &__image {
            height: 100%;
            width: 40%;

            @include mq(sm) {
                width: 35%;
            }

            img {
                width: 100%;
                height: auto;

                @include mq(sm) {
                    height: 100%;
                    width: auto;
                }
            }
        }

        &__price {
            font-size: 14px;
            margin: 0;
            line-height: 1.3;

            @include mq(sm) {
                font-size: 1.4vw;
            }
        }

        &__quantity {
            text-align: center;

            @include mq(sm) {
                text-align: none;
                display: flex;
                align-items: center;
            }
        }

        &__amount {
            font-size: 14px;
            margin: 0;
            line-height: 1.3;

            @include mq(sm) {
                font-size: 1.4vw;
            }
        }

        &__delete-button {
            border: none;
            background: none;
            display: flex;
            cursor: pointer;
            outline: none;
            align-items: center;
            justify-content: flex-end;

            p {
                display: none;

                @include mq(sm) {
                    text-transform: uppercase;
                    color: $mainColor;
                    margin: 0;
                    line-height: 0;
                    font-size: 1vw;
                    display: block;
                }
            }

            i {
                color: $mainColor;
                position: absolute;
                top: 8px;
                right: 0;

                @include mq(sm)  {
                    position: static;
                    top: auto;
                    right: auto;
                    transform: scale3d(1.5,1.5,1.5);
                    display: inline-block;
                    margin-right: 20px;
                }
            }
        }
    }

    &__subtotal,
    &__total {
        width: 100%;
        padding: 1vw 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;

        @include mq(sm) {
            margin-bottom: 0;
        }

        &__text {
            margin-right: 2vw;
        }
    }

    &__total {
        text-transform: uppercase;
        background: $lightGray;
        justify-content: space-between;
        padding: 5px 10px;

        @include mq(sm) {
            padding: 1vw;
            margin-bottom: 1.5vw;
        }

        &__number {
            font-weight: 900;
        }
    }

    &__buttons {
        margin-bottom: 3vw;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;

        @include mq(sm) {
            flex-direction: row;
        }

        &__button {
            border-radius: 50px;
            font-weight: 500;
            width: 80%;
            padding: 10px 20px;
            text-align: center;
            margin: 10px auto;
            cursor: pointer;

            @include mq(sm) {
                margin: 0;
                padding: .5vw 2.5vw;
                width: auto;
            }

            &:nth-child(1) {
                background: $lightGray;
                color: $black;
            }

            &:nth-child(2) {
                background: $lila;
                color: $white;
            }
        }
    }

    &__pedido {
        @include trans;
        position: fixed;
        z-index: -1;
        background: rgba(0,0,0,.7);
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        pointer-events: none;
        opacity: 0;

        &__form {
            @include trans;

            width: 100%;
            max-width: 700px;
            opacity: 0;
            transform: translateY(-50%);
        }

        &__heading {
            background: $mainColor;
            color: $white;
            font-family: $mainFont;
            padding: 15px;

            @include mq(md) {
                padding: 30px;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 900;
                text-align: center;
                margin: 0;
            }
        }

        &__body,
        &__footer {
            background: $white;
            padding: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include mq(md) {
                padding: 30px;
            }
        }

        &__footer {
            background: $lightestGray;
        }

        &__group {
            display: flex;
            padding: 10px 0;
            width: 100%;
            justify-content: space-between;

            @include mq(sm) {
                width: 48%;
            }
        }

        &__divider {
            width: 100%;
            padding: 10px 0;

            p {
                color: $mainColor;
                font-weight: 600;
                text-align: center;
                margin: 0;
            }
        }

        &__input {
            @include trans;

            flex-grow: 0;
            flex-shrink: 1;
            height: 40px;
            background: $lightestGray;
            padding: 0 15px;
            border: none;
            border-bottom: 2px solid $lightestGray;
            width: 100%;
            min-width: none;

            &:focus {
                outline: none;
                border-bottom: 2px solid $mainColor;
            }

            &:not(:first-child) {
                margin-left: 10px;
            }

            &--small {
                width: 22%;
                flex-shrink: 0;
                flex-grow: 1;
            }
        }

        &__button {
            @include trans;

            cursor: pointer;
            background: $mainColor;
            border: 2px solid $mainColor;
            color: $white;
            text-transform: uppercase;
            height: 50px;
            display: inline-flex;
            align-items: center;
            padding: 0 30px;
            font-weight: 600;
            border-radius: 25px;

            &::disabled {
                opacity: .3;
                filter: grayscale(100%);
            }

            &--cancel {
                color: $mainColor;
                background: transparent;
            }
        }

        &.-active {
            z-index: 10000;
            opacity: 1;
            pointer-events: all;

            .carro-de-compras__pedido__form {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
