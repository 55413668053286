.footer {

    @include mq(sm) {
        height: auto;
    }

    &__icon {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        img {
            max-width: 100%;
        }
    }

    &__primary {
        background: $mainColor;
        padding: 60px 20px 20px 20px;

        @include mq(sm) {
            height: 10vw;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        @include mq(sm) {
            margin-bottom: 0;
        }

        i {
            color: $white;
            font-size: 50px;
            margin-bottom: 10px;
        }
    }

    &__text {
        margin: 0;
        color: $white;
        font-size: 20px;
    }

    &__secondary {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px 40px;

        @include mq(sm) {
            padding: 5% 0;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;

        @include mq(sm) {
            height: 3vw;
            width: auto;
            margin-bottom: 0;
        }

        img {
            width: 100%;;
            height: auto;

            @include mq(sm) {
                width: auto;
                height: 100%;
            }
        }
    }

    &__address,
    &__contact {
        margin-bottom: 40px;

        @include mq(sm) {
            margin: 0;
            line-height: 1.7;
            text-align: left;
            display: inline-block;

        }
    }

    &__social {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        li {
            margin-left: 5%;
            margin-right: 5%;
        }

        a {
            color: $darkGray;
            font-size: 24px;
        }
    }
}
