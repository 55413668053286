@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
	font-family: 'Futura';
	src: url('/fonts/subset-Futura-Light.eot');
	src: url('/fonts/subset-Futura-Light.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-Futura-Light.woff2') format('woff2'),
		url('/fonts/subset-Futura-Light.woff') format('woff'),
		url('/fonts/subset-Futura-Light.ttf') format('truetype'),
		url('/fonts/subset-Futura-Light.svg#Futura-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Futura';
	src: url('/fonts/subset-Futura-Book.eot');
	src: url('/fonts/subset-Futura-Book.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-Futura-Book.woff2') format('woff2'),
		url('/fonts/subset-Futura-Book.woff') format('woff'),
		url('/fonts/subset-Futura-Book.ttf') format('truetype'),
		url('/fonts/subset-Futura-Book.svg#Futura-Book') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Futura';
	src: url('/fonts/subset-Futura-Bold.eot');
	src: url('/fonts/subset-Futura-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-Futura-Bold.woff2') format('woff2'),
		url('/fonts/subset-Futura-Bold.woff') format('woff'),
		url('/fonts/subset-Futura-Bold.ttf') format('truetype'),
		url('/fonts/subset-Futura-Bold.svg#Futura-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
}
