.producto {
    overflow: hidden;
    &__hero {

        @include mq(sm) {
            height: 85vh;
            width: 100%;
        }

        &__bottle-malbec,
        &__bottle-blanc,
        &__bottle-malbec-reserva,
        &__bottle-pasita {
            padding-top: 40px;

            @include mq(sm) {
                padding-top: 0;
                position: relative;
                height: 85vh;

            }

            img  {
                display: none;

                @include mq(sm) {
                    display: block;
                    position: absolute;
                    width: auto;
                    height: 95%;
                    top: 5%;
                    left: 50%;
                    bottom: 0;
                    right: 0;
                    transform: translate3d(-50%, 5%, 0) rotate(15deg) scale3d(1.3,1.3,1.3);
                }
            }
        }

        &__bottle-malbec {
            background: url('../images/home/pattern2.gif');
        }

        &__bottle-blanc {
            background: url('../images/home/pattern3.gif');
        }

        &__bottle-malbec-reserva {
            background: url('../images/home/pattern4.gif');
        }

        &__bottle-pasita {
            background: url('../images/home/pattern1.gif');

            img  {
                display: none;

                @include mq(sm) {
                    display: block;
                    position: absolute;
                    width: auto;
                    height: 85%;
                    top: 10%;
                    left: 50%;
                    bottom: 0;
                    right: 0;
                    transform: translate3d(-50%, 5%, 0) rotate(15deg) scale3d(1.3,1.3,1.3);
                }
            }
        }

        &__row {
            width: 100%;
            display: flex;

            &--data-malbec,
            &--data-blanc,
            &--data-malbec-reserva,
            &--data-pasita {
                display: flex;
                justify-content: center;
                height: 65vh;
                flex-wrap: wrap;

                @include mq(sm) {
                    flex-wrap: nowrap;
                    flex-direction: column;
                }
            }

            &--data-malbec {
                background: url('../images/home/pattern2.gif');
            }

            &--data-blanc {
                background: url('../images/home/pattern3.gif');
            }

            &--data-malbec-reserva {
                background: url('../images/home/pattern4.gif');
            }

            &--data-pasita {
                background: url('../images/home/pattern1.gif');
            }

            &--description {
                height: 20vh;
            }
        }

        &__data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;

            @include mq(sm) {
                align-items: flex-start;
                width: 50%;
                padding: 0;
            }
        }

        &__title {
            margin: 0;
            color: $white;
            text-transform: uppercase;
            margin-bottom: 5%;
            font-size: 60px;
            font-weight: 700;
        }

        &__col {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 60%;

            @include mq(sm) {
                flex-direction: row;
                width: 100%;
            }

            &--bottle {
                width: 40%;

                @include mq(sm) {
                    display: none;
                }

                img {
                    height: auto;
                    width: 100%;
                    margin-top: -60%;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            .quantity-selector {
                color: $white;
                margin-bottom: 20px;

                @include mq(sm) {
                    margin-bottom: 0;
                }

                i {
                    @include mq(sm) {
                        font-size: 40px;
                    }
                }

                span {
                    @include mq(sm) {
                        font-size: 30px;
                    }
                }
            }
        }

        &__text {
            margin: 0;
            margin-bottom: 5%;
            color: $white;
            text-align: center;
            width: 90%;

            @include mq(sm) {
                text-align: left;
                width: auto;
            }
        }

        &__price {
            color: $white;
            margin-bottom: 5%;
            font-size: 30px;
            text-align: center;
            width: 60%;
            align-self: flex-end;

            @include mq(sm) {
                width: auto;
                align-self: auto;
            }
        }

        &__button {
            background: $white;
            padding: 10px 20px;
            font-weight: 700;
            color: $black;
            border-radius: 50px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            width: 80%;

            @include mq(sm) {
                font-size: 16px;
                width: auto;
                display: block;
                margin-left: 5vw;
            }
        }

        &__quantity {
            margin: 0;
            color: $white;
            margin-bottom: 20px;
            font-size: 20px;

            @include mq(sm) {
                margin-bottom: 0;
                margin-right: 10%;
            }
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 50%;
            padding: 20px;
            position: relative;
            flex-direction: column;

            @include mq(sm) {
                padding: 40px;
                flex-direction: row;
            }

            &--malbec,
            &--pasita {
                background: $lila;
            }

            &--blanc {
                background: $yellow;
            }

            &--malbec-reserva {
                background: $brown;
            }

            img {
                width: 50px;
                height: 50px;

            }

            p {
                text-transform: uppercase;
                color: $white;
                margin: 0;
                font-size: 14px;
            }
        }

        &__image {
            width: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &__origen {
        height: 90vh;
        padding-top: 5%;
        padding-bottom: 5%;
        overflow: hidden;

        &__wrapper {
            &--text {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                padding-top: 20px;
                padding-bottom: 20px;

                @include mq(sm) {
                    padding-top: 15%;
                    padding-bottom: 0;
                }
            }

            &--images {
                width: 100%;
                height: 90vh;
                position: relative;
            }
        }

        &__imagen {
            &--big {
                overflow: hidden;

                @include mq(sm) {
                    width: 90%;
                    height: 60%;

                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &--small {
                overflow: hidden;

                @include mq(sm) {
                    width: 40%;
                    height: 30%;
                    position: absolute;
                    bottom: 25%;
                    left: -30%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    &__bottle-display {
        &__container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vw;

            @include mq(sm) {
                height: 33.34vw;
            }

            img {
                width: auto;
                height: 80%;
            }

            &--left {
                background: url('../images/home/pattern3.gif');
            }

            &--center {
                background: url('../images/home/pattern4.gif');
            }

            &--right {
                background: url('../images/home/pattern1.gif');
            }
        }
    }
}
