.header {

    $h: &;

    position: fixed;
    top:0;
    left:0;
    right:0;
    background: $white;
    z-index: 1000;
    height: 10vh;
    width: 100%;
    // overflow: hidden;

    @include mq(sm) {
        position: relative;
        height: auto;
    }

    &__container {
        position: relative;
        height: 10vh;

        @include mq(sm) {
            height: 80px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__logo {
        position: absolute;
        height: 30px;
        top: 15px;

        @include mq(sm) {
            height: 50px;
            top: 15px;
            left: 0;
        }

        img {
            height: 30px;
            width: auto;

            @include mq(sm) {
                height: 50px;
            }
        }
    }

    &__nav {
        @include trans;

        position: absolute;
        top: 10vh;
        background: transparentize($black, .15);
        transform: translateX(100%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 90vh;
        width: calc(100% + 30px);

        @include mq(sm){
            position: relative;
            background: none;
            width: 30%;
            padding: 0;
            transform: translate(0);
            justify-content: space-around;
            top: 0;
            height: auto;
        }

        a {
            font-weight: 700;

            @include mq(sm) {
                color: $black;
            }
        }
    }

    &__menu {
        @include mq(sm) {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }

        li {
            margin-bottom: 20px;
            font-size: 30px;

            @include mq(sm) {
                font-size: 20px;
                margin-bottom: 0;
            }
        }
    }

    &__buttons {
        position: absolute;
        right: 20%;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 25%;

        @include mq(sm) {
            width: 10%;
            display: flex;
            position: relative;
            transform: translate3d(0,0,0);
            justify-content: space-evenly;
            top: 0;
            right: 0;
        }
    }

    &__button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        i {
            font-size: 28px;
            color: $black;

            @include mq(sm) {
                font-size: 28px;
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 22px;
        top: 22px;

        span {
            background-color: $black;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(sm) {
            display: none;
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(-5%);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
